import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AppBar } from './MenuStyle';
import React, { useState } from 'react';
import { Avatar, Grid, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import styled from 'styled-components';
import { history } from '../../helpers/history';

interface IHeader {
  open: boolean;
  handleClickDrawer: any;
  titulo: string;
}

const Header: React.FC<IHeader> = ({ open, handleClickDrawer, titulo }) => {
  const [textoBusca, setTextoBusca] = useState<string>('');

  const submitSearch = (e: any) => {
    e.preventDefault();
    history.push(`/assessorados?busca=${textoBusca}`);
  };

  const handleProfileClick = () => {
    // Ação quando o perfil é clicado, por exemplo, abrir o menu de perfil
  };

  return (
    <AppBar position='fixed' open={open}>
      <ToolbarStato>
        <IconButton
          aria-label='open drawer'
          onClick={handleClickDrawer}
          edge='start'
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container>
          <Grid item lg={7} md={6} sm={8}>
            <Typography
              style={{ color: '#585858' }}
              variant='h1'
              noWrap
              component='div'
            >
              {titulo}
            </Typography>
          </Grid>

          <Grid item lg={4} md={5} sm={3}>
            <form onSubmit={submitSearch}>
            <TextField
              label='Pesquisar Assessorados'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setTextoBusca(e.target.value)}
              onSubmit={submitSearch}
            />
            </form>
          </Grid>
          <Grid item md={1} sm={1}>
            <IconButton onClick={handleProfileClick}>
              <Avatar alt='Foto do Perfil' src={'https://miro.medium.com/v2/resize:fit:1400/1*g09N-jl7JtVjVZGcd-vL2g.jpeg'} />
            </IconButton>
          </Grid>
        </Grid>
      </ToolbarStato>
    </AppBar>
  );
};

const ToolbarStato = styled(Toolbar)`
  height: 100%;
`;

export default Header;
