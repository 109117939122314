import { useEffect, useState } from "react";
import { IReuniao } from "../../commons/types/crm";
import { Paper, createTheme, Box, Tab, Tabs } from "@mui/material";
import styled, { ThemeProvider } from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/material/locale";
import { columns } from "../Dashboard/tableReunioes";
import { useReuniaoActions } from "../../states/reunioes/reuniao.actions";
import { toast } from "react-toastify";
import DrawerConcluir from "../Dashboard/Components/DrawerConcluir";
import {
  CustomTabPanel,
  a11yProps,
} from "../../components/Tabs/accordionAndTabPanel";
import ReunioesAssessorados from "./Components/ReunioesAssessorados";
import ReunioesInternas from "./Components/ReunioesInternas";

const Reunioes: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PaperReunioes elevation={3}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "0px 25px !important",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="tabs assessorado detalhes"
          >
            <Tab label="Reuniões" {...a11yProps(0)} />
            <Tab label="Reuniões internas" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ReunioesAssessorados />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ReunioesInternas />
        </CustomTabPanel>
      </Box>
    </PaperReunioes>
  );
};

export const PaperReunioes = styled(Paper)`
  padding: 30px;
  border-radius: 10px;
  margin: 20px;
  width: 100%;
`;

export default Reunioes;
