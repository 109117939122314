/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { styled } from 'styled-components';
import { authAtom } from '../../../../states/usuarios/AuthState';
import { useUsuarioActions } from '../../../../states/usuarios/usuario.actions';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import { SelectStato } from '../../../../commons/styleds/selects';
import { ButtonStato } from '../../../../commons/styleds/buttons';
import { useFaixaSalarialActions } from '../../../../states/config/faixasSalariais/faixaSalarial.actions';
import { useConfigAreaActions } from '../../../../states/config/areas/configArea.actions';
import { useConfigSubAreaActions } from '../../../../states/config/subAreas/configSubArea.actions';
import { useConfigSegmentoActions } from '../../../../states/config/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../../states/config/setores/setor.actions';
import { useNivelDeCargoActions } from '../../../../states/config/niveisDeCargos/nivelDeCargo.actions';
import { useIdiomaConfigActions } from '../../../../states/config/idiomas/idioma.actions';
import { useMercadoAlvoActions } from '../../../../states/mercadoAlvo/mercadoAlvo.actions';
import { TextFieldStato } from '../../../../commons/styleds/textFields';
import { useUsuarioOneActions } from '../../../../states/usuariosOne/usuarioOne.actions';
import { IMercadoAlvo } from '../../../../commons/types/mercadoAlvo';
import { IUsuario } from '../../../../commons/types/usuatios';
import { IConfigArea, IConfigSegmento, IConfigSubArea, IFaixaSalarial, IIdioma, INivelDeCargo, ISetor } from '../../../../commons/types/config';
import { useComandaActions } from '../../../../states/comandas/comanda.actions';
import { IComanda, IContato, IMercadoAlvoRegiao, TipoRegiao } from '../../../../commons/types/crm';
import TableRegiao from './components/tableRegiao';

interface IParams {
  id?: string;
}

const empty: IMercadoAlvo = {
  id: 0
};

const MercadoAlvoCrm: React.FC = () => {
  const methods = useForm({ defaultValues: empty });

  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const [loading, setLoading] = useState(true);
  const [possuiMercadoAlvo, setPossuiMercadoAlvo] = useState<boolean | undefined>(undefined);

  const comandaActions = useComandaActions();
  const mercadoAlvoActions = useMercadoAlvoActions();

  const [regioes, setRegioes] = useState<IMercadoAlvoRegiao[]>([]);

  const usuariosOneActions = useUsuarioOneActions();
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

  const faixaSalarialActions = useFaixaSalarialActions();
  const [faixaSalariais, setFaixaSalariais] = useState<IFaixaSalarial[]>([]);

  const configAreaActions = useConfigAreaActions();
  const [areas, setAreas] = useState<IConfigArea[]>([]);

  const configSubAreaActions = useConfigSubAreaActions();
  const [subAreas, setSubAreas] = useState<IConfigSubArea[] | undefined>(undefined);

  const configSegmentoActions = useConfigSegmentoActions();
  const [segmentos, setSegmento] = useState<IConfigSegmento[] | undefined>(undefined);

  const configSetorActions = useSetorActions();
  const [setores, setSetor] = useState<ISetor[] | undefined>(undefined);

  const nivelDeCargoActions = useNivelDeCargoActions();
  const [niveisDeCargos, setNiveisDeCargos] = useState<INivelDeCargo[]>([]);

  const idiomasActions = useIdiomaConfigActions();
  const [idiomas, setIdiomas] = useState<IIdioma[] | undefined>(undefined);

  const [idAssessorado, setIdAssessorado] = useState(0);

  useEffect(() => {
    setLoading(true);

    comandaActions
      .get(Number(id))
      .then((data: IComanda) => {
        setIdAssessorado(data.contato?.id ?? 0);

        setTimeout(() => {
          mercadoAlvoActions
            .list(false, `?contatoId=${data.contato?.id}`)
            .then((resp: any) => {
              if (resp.length > 0) {
                atualizarFormulario(resp[0]);
              }

              setPossuiMercadoAlvo(true);
            })
            .catch((err: any) => {
              toast.warn(err)
              setLoading(false);
              setPossuiMercadoAlvo(false);
            });
        }, 1000);
      })
      .catch((error) => toast.error(error))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });

    configAreaActions
      .list()
      .then((resp: IConfigArea[]) => {
        setAreas(resp);
      })
      .catch((err: any) => toast.warn(err));

    configSubAreaActions
      .list()
      .then((resp: IConfigSubArea[]) => setSubAreas(resp))
      .catch((err: any) => toast.warn(err));

    configSegmentoActions
      .list()
      .then((resp: IConfigSegmento[]) => setSegmento(resp))
      .catch((err: any) => toast.warn(err));

    faixaSalarialActions
      .list()
      .then((resp: IFaixaSalarial[]) => setFaixaSalariais(resp))
      .catch((err: any) => toast.warn(err));

    configSetorActions
      .list()
      .then((resp: ISetor[]) => setSetor(resp))
      .catch((err: any) => toast.warn(err));

    nivelDeCargoActions
      .list()
      .then((resp: INivelDeCargo[]) => setNiveisDeCargos(resp))
      .catch((err: any) => toast.warn(err));

    idiomasActions
      .list()
      .then((resp: IIdioma[]) => {
        setIdiomas(resp)
      })
      .catch((err: any) => toast.warn(err));

    usuariosOneActions
      .list()
      .then((resp: any) => setUsuarios(resp.resultado))
      .catch((err: any) => toast.warn(err));

  }, []);

  const onSubmit = async (data: IMercadoAlvo) => {
    if (idAssessorado === 0) {
      toast.warn('Falha ao salvar, contato não localizado');
      return;
    }
    let idMercadoAlvo = 0;
    if (data.id > 0)
      idMercadoAlvo = data.id;

    data.contatoId = idAssessorado;
    data.mercadoAlvoAreas = areas?.filter((area) => data.idMercadoAlvoAreas!.includes(area.id!)).map((x) => { return { idArea: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoSubAreas = subAreas?.filter((subArea) => data.idMercadoAlvoSubAreas!.includes(subArea.id!)).map((x) => { return { idSubArea: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoSegmentos = segmentos?.filter((segmento) => data.idMercadoAlvoSegmentos!.includes(segmento.id!)).map((x) => { return { idSegmento: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoSetores = setores?.filter((setor) => data.idMercadoAlvoSetores!.includes(setor.id!)).map((x) => { return { idSetor: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoNiveisCargos = niveisDeCargos?.filter((nivel) => data.idMercadoAlvoNiveisCargos!.includes(nivel.id!)).map((x) => { return { idNivelCargo: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoIdiomas = idiomas?.filter((idioma) => data.idMercadoAlvoIdiomas!.includes(idioma.id!)).map((x) => { return { idIdioma: x.id!, idMercadoAlvo: idMercadoAlvo } });
    data.mercadoAlvoAgentesMercados = usuarios?.filter((usuario) => data.idMercadoAlvoAgentesMercados!.includes(usuario.id!)).map((x) => { return { idUsuario: x.id!, idMercadoAlvo: idMercadoAlvo } });

    if (data.id <= 0) {
      mercadoAlvoActions.create(data)
        .then((resp: IMercadoAlvo) => {
          atualizarFormulario(resp);
          toast.success('Salvo com sucesso !');
        })
        .catch((err: any) => toast.warn(err));
    } else {
      mercadoAlvoActions.update(data.id, data)
        .then((resp: IMercadoAlvo) => {
          atualizarFormulario(resp);
          toast.success('Salvo com sucesso !');
        })
        .catch((err: any) => toast.warn(err));
    }
  };

  const atualizarFormulario = (result: any) => {
    setRegioes(result.mercadoAlvoRegioes);
    result.idMercadoAlvoAreas = result.mercadoAlvoAreas.map((x: any) => x.idArea)
    result.idMercadoAlvoSubAreas = result.mercadoAlvoSubAreas.map((x: any) => x.idSubArea)
    result.idMercadoAlvoSegmentos = result.mercadoAlvoSegmentos.map((x: any) => x.idSegmento)
    result.idMercadoAlvoSetores = result.mercadoAlvoSetores.map((x: any) => x.idSetor)
    result.idMercadoAlvoNiveisCargos = result.mercadoAlvoNiveisCargos.map((x: any) => x.idNivelCargo)
    result.idMercadoAlvoAgentesMercados = result.mercadoAlvoAgentesMercados.map((x: any) => x.idUsuario)
    result.idMercadoAlvoIdiomas = result.mercadoAlvoIdiomas.map((x: any) => x.idIdioma)
    result.palavraChave = result.palavraChave;
    result.pretensaoSalarioId = result.pretensaoSalarioId;
    result.ultimoSalarioId = result.ultimoSalarioId;
    result.modeloTrabalho = result.modeloTrabalho;
    methods.reset(result, { keepDefaultValues: true });
  };

  return (
    <>
      <FormProvider {...methods}>
        <FormStato
          id='formArea'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {!loading && possuiMercadoAlvo ? (
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                rowGap: '10px',
              }}
            >
              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',

              }}>
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name='idMercadoAlvoAreas'
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {areas && (
                          <MultiSelect
                            opcoes={areas as IConfigArea[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Area'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name='idMercadoAlvoSubAreas'
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {subAreas && (
                          <MultiSelect
                            opcoes={subAreas as IConfigSubArea[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Sub Area'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',
              }}>
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name='idMercadoAlvoAgentesMercados'
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {usuarios && (
                          <MultiSelectNome
                            opcoes={usuarios as IUsuario[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Agente de Mercado'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name='idMercadoAlvoSegmentos'
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {segmentos && (
                          <MultiSelect
                            opcoes={segmentos as IConfigSegmento[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Segmentos'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',
              }}>
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name='idMercadoAlvoSetores'
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {setores && (
                          <MultiSelect
                            opcoes={setores as ISetor[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Setor'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name='idMercadoAlvoNiveisCargos'
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {niveisDeCargos && (
                          <MultiSelect
                            opcoes={niveisDeCargos as INivelDeCargo[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Nivel de Cargo'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',
              }}>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  {areas && (
                    <Controller
                      control={methods.control}
                      name='palavraChave'
                      render={({ field: { ref, ...field } }) => (
                        <FormControl
                          fullWidth
                          variant='outlined'
                        >
                          <TextFieldStato
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label='Palavra Chave'
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name='pretensaoSalarioId'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {faixaSalariais && <>
                          <InputLabel id='demo-simple-select-label'>Pretensão/Salário</InputLabel>
                          <SelectStato {...field} required label='Pretensão/Salário'>
                            {faixaSalariais.map((faixa: IFaixaSalarial, index: number) => (
                              <MenuItem value={faixa.id} key={index}>
                                {faixa.nome}
                              </MenuItem>
                            ))}
                          </SelectStato>
                        </>
                        }
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',
              }}>
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name='ultimoSalarioId'
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {faixaSalariais && <>
                          <InputLabel id='demo-simple-select-label'>Último Salário</InputLabel>
                          <SelectStato {...field} required label='Último Salário'>
                            {faixaSalariais.map((faixa: IFaixaSalarial, index: number) => (
                              <MenuItem value={faixa.id} key={index}>
                                {faixa.nome}
                              </MenuItem>
                            ))}
                          </SelectStato>
                        </>}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name='idMercadoAlvoIdiomas'
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        {idiomas && (
                          <MultiSelectNome
                            opcoes={idiomas as IIdioma[]}
                            setValor={onChange}
                            valor={value || []}
                            label='Idiomas'
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <GridItemCadastro item xs={12} md={3}>
                  <Typography variant='button' display='block' gutterBottom>
                    Modelo de trabalho pretendido:
                  </Typography>
                </GridItemCadastro>
                <GridItemCadastro item xs={12} md={9}>
                  {areas && (
                    <Controller
                      control={methods.control}
                      name='modeloTrabalho'
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          row
                          aria-labelledby='radio-buttons-modeloComercial-label'
                          name='radio-buttons-modeloComercial'
                        >
                          <FormControlLabel
                            value={'modeloRemoto'}
                            control={<Radio />}
                            label={'Modelo Remoto'}
                          />
                          <FormControlLabel
                            value={'modeloHibrido'}
                            control={<Radio />}
                            label={'Modelo Hibrido'}
                          />
                          <FormControlLabel
                            value={'modeloPresencial'}
                            control={<Radio />}
                            label={'Modelo Presencial'}
                          />
                        </RadioGroup>
                      )}
                    />
                  )}
                </GridItemCadastro>
              </Grid>

              <TableRegiao regioes={regioes} tipo={TipoRegiao.DISPONIBILIDADE} />
              <TableRegiao regioes={regioes} tipo={TipoRegiao.RESTRICAO} />

              <Grid item xs={12} md={12} sm={12} sx={{
                display: 'flex',
              }}>
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <GridItemCadastroBtnSalvar item xs={11} md={11}>
                    <ButtonStato
                      disabled={!methods.formState.isValid}
                      variant="contained"
                      type='submit'
                    >
                      Salvar
                    </ButtonStato>
                  </GridItemCadastroBtnSalvar>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              {!loading && possuiMercadoAlvo === false
                ? <h3>
                  Este contato não possui Mercado Alvo, verifique se ele possui
                  a opção "Relacionamento/Mercado" ativa na Comanda atual
                </h3>
                :
                <CircularProgress
                  style={{
                    width: '60px',
                    height: '60px',
                    position: 'relative',
                    left: '50%',
                    top: '25%',
                  }}
                />}
            </>
          )}
        </FormStato>
      </FormProvider>


    </>
  );
};

const GridCadastro = styled(Grid)`
  margin: 0px !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 97% !important;
  margin-right: 0px !important;
  justify-content: space-between !important;
  padding: 15px 0%;
`;

export const GridItemCadastro = styled(Grid)`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const Separador = styled(Grid)`
  padding: 3px;
`;

export const GridItemLinhaCadastro = styled(Grid)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between !important;
`;

const FormStato = styled.form`
  padding: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  position: fixed;
  bottom: 2%;
  right: 4%;
  z-index: 50;
`;

export default MercadoAlvoCrm;

