import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IReuniao, IReuniaoInterna, StatusReuniaoEnum } from '../../../commons/types/crm';
import { ThemeProvider } from 'styled-components';
import { Button, createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { DataGrid, GridColDef, GridSlots, type GridCellParams } from '@mui/x-data-grid';
import { useReuniaoInternaActions } from '../../../states/reunioesInternas/reunioesInternas.actions';
import moment from 'moment';
import DrawerReuniaoInterna from './DrawerReuniaoInterna';
import { GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';

interface AddToolbarProps {
  openAdd: Function;
}

function AddToolbar(props: AddToolbarProps) {
  const { openAdd } = props;

  const handleClick = () => {
    openAdd();
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Adicionar nova reunião
      </Button>
    </GridToolbarContainer>
  );
}

const ReunioesInternas: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [reunioes, setReunioes] = useState<IReuniaoInterna[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [reuniao, setReuniao] = useState<IReuniaoInterna>();
  const actions = useReuniaoInternaActions();

  useEffect(() => {
    actions
      .list()
      .then((resp: IReuniaoInterna[]) => setReunioes(resp))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 220,
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 100,
      valueGetter(value: any, row: IReuniao) {
        const reuniao = row as IReuniao;
        if (
          reuniao.status === StatusReuniaoEnum.PENDENTE_APROVACAO ||
          reuniao.status === StatusReuniaoEnum.APROVADA
        )
          return moment(reuniao.dtRealizadaInicio).format('DD/MM');
        return moment(reuniao.dtPrevista).format('DD/MM');
      },
    },
    {
      field: 'horaInicio',
      headerName: 'Início',
      width: 100,
      valueGetter(params: any, row: IReuniao) {
        const reuniao = row;
        return dayjs(reuniao.dtRealizadaInicio!).format('HH:mm');
      },
    },
    {
      field: 'horaFim',
      headerName: 'Fim',
      width: 100,
      valueGetter(params: any, row: IReuniao) {
        const reuniao = row;
        return dayjs(reuniao.dtRealizadaFim).format('HH:mm');
      },
    },
    {
      field: 'valorHora',
      headerName: 'Valor Hora',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return (
          <> {obj.valorHora ? 'R$ ' + parseFloat(obj.valorHora!.toString()).toFixed(2) : ''} </>
        );
      },
    },
    {
      field: 'statusValidacao',
      headerName: 'Status',
      width: 160,
    },
  ];

  const openAdd = () => {
    setReuniao(undefined);
    setOpen(true);
  };

  return (
    <>
      <ThemeProvider theme={createTheme({}, ptBR)}>
        <DataGrid
          rows={reunioes}
          columns={columns}
          loading={loading}
          sx={{
            borderRadius: '10px',
            minHeight: '550px',
            height: 'auto',
          }}
          slots={{
            toolbar: AddToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: { openAdd },
          }}
        />
      </ThemeProvider>
      <DrawerReuniaoInterna
        reuniao={reuniao}
        open={open}
        setOpen={setOpen}
        loadingSave={loadingSave}
        setLoadingSave={setLoadingSave}
        setReunioes={setReunioes}
      />
    </>
  );
};

export default ReunioesInternas;
