import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IReuniao } from '../../../commons/types/crm';
import { useReuniaoActions } from '../../../states/reunioes/reuniao.actions';
import { ThemeProvider } from 'styled-components';
import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from '../../Dashboard/tableReunioes';

const ReunioesAssessorados: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [reunioes, setReunioes] = useState<IReuniao[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const reuniaoActions = useReuniaoActions();
  const [reuniao, setReuniao] = useState<IReuniao>();

  useEffect(() => {
    reuniaoActions
      .list()
      .then((resp: any) => setReunioes(resp.resultado))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ThemeProvider theme={createTheme({}, ptBR)}>
      <DataGrid
        rows={reunioes}
        loading={loading}
        columns={columns({
          open,
          setOpen,
          loadingSave,
          setLoadingSave,
          setReuniao,
        })}
        sx={{
          borderRadius: '10px',
          minHeight: '700px',
          height: 'auto',
        }}
      />
    </ThemeProvider>
  );
};

export default ReunioesAssessorados;
