import {
  FormControl,
  Grid,
  Drawer,
  Typography,
  LinearProgress,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { IconDrawer } from '../../../commons/styleds/icons';
import { GridItemCadastroBtnSalvar } from '../../../commons/styleds/grids';
import { ButtonStato } from '../../../commons/styleds/buttons';
import { IReuniaoInterna, StatusReuniaoEnum } from '../../../commons/types/crm';
import { useState } from 'react';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from 'react-toastify';
import { useReuniaoInternaActions } from '../../../states/reunioesInternas/reunioesInternas.actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import { FormControlStato } from '../../../commons/styleds/styleds';
import { NumericFormat } from 'react-number-format';
import { TextFieldStato } from '../../../commons/styleds/textFields';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface IDrawerConcluir {
  reuniao?: IReuniaoInterna;
  setReuniao?: Function;
  open: boolean;
  setOpen: Function;
  loadingSave: boolean;
  setLoadingSave: Function;
  setReunioes: Function;
}

const DrawerReuniaoInterna: React.FC<IDrawerConcluir> = ({
  reuniao,
  open,
  setOpen,
  loadingSave,
  setLoadingSave,
  setReunioes,
}) => {
  const [descricao, setDescricao] = useState('');
  const [dataRealizada, setDataRealizada] = useState(
    reuniao ? dayjs(reuniao?.dtPrevista) : null,
  );
  const [dataRealizadaInicio, setDataRealizadaInicio] = useState(
    reuniao ? dayjs(reuniao?.dtRealizadaInicio) : null,
  );
  const [dataRealizadaFim, setDataRealizadaFim] = useState(
    reuniao ? dayjs(reuniao?.dtRealizadaFim) : null,
  );
  const [valorHora, setValorHora] = useState(reuniao?.valorHora ?? 0);
  const reuniaoActions = useReuniaoInternaActions();
  const auth = useRecoilValue(authAtom);

  const reset = () => {
    setDescricao('');
    setDataRealizada(null);
    setDataRealizadaInicio(null);
    setDataRealizadaFim(null);
  };

  const closeDrawer = () => {
    setLoadingSave(false);
    setOpen(false);
    reset();
  };

  const inserirRuniao = () => {
    setLoadingSave(true);

    const dataInicio = dataRealizadaInicio!
      .year(dataRealizada!.year())
      .month(dataRealizada!.month())
      .date(dataRealizada!.date())
      .format('YYYY-MM-DDTHH:mm:ss');

    const dataFim =dataRealizadaFim!
      .year(dataRealizada!.year())
      .month(dataRealizada!.month())
      .date(dataRealizada!.date())
      .format('YYYY-MM-DDTHH:mm:ss');

    const data: IReuniaoInterna = {
      id: 0,
      descricao,
      dtPrevista: dataRealizada as any,
      dtRealizadaInicio: dataInicio,
      dtRealizadaFim: dataFim,
      idConsultor: auth?.id!,
      idUsuario: null,
      status: StatusReuniaoEnum.APROVADA,
      valorHora,
      ativo: true,
    };

    reuniaoActions
      .create(data)
      .then((resp: any) => {
        setReunioes((prev: any) => [...prev, data]);
        toast.success('Reunião inserida com sucesso.');
      })
      .catch((e) => toast.error(e ?? 'Falha ao inserir reunião.'))
      .finally(() => closeDrawer());
  };

  return (
    <Drawer
      style={{ minWidth: '250px' }}
      anchor={'right'}
      open={open}
      onClose={() => closeDrawer()}
    >
      <Grid item xs={12} md={12} style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}>
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => closeDrawer()}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant="h5" sx={{ paddingBottom: '20px' }}>
              Adicionar Reunião
            </Typography>
          </Grid>
        </Grid>

        <FormControlStato sx={{ width: '100%', marginBottom: '15px' }} variant="filled">
          <TextField
            id="filled-basic"
            label="Descrição"
            type="text"
            required
            value={descricao}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescricao(event.target.value);
            }}
          />
        </FormControlStato>

        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: 'Empty',
              todayButtonLabel: 'Now',
            }}
          >
            <DesktopDatePicker
              value={dataRealizada}
              label="Data Realizada"
              format="DD/MM/YYYY"
              timezone={'UTC'}
              defaultValue={dataRealizada}
              onChange={(value: any) => {
                setDataRealizada(value);
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
            >
              <DemoItem label={'Hora Inicio'}>
                <TimePicker
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  ampm={false}
                  ampmInClock={false}
                  value={dataRealizadaInicio}
                  onChange={(value: any) => {
                    setDataRealizadaInicio(dayjs(value));
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
            >
              <DemoItem label={'Hora Fim'}>
                <TimePicker
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  ampm={false}
                  value={dataRealizadaFim ? dayjs(dataRealizadaFim) : null}
                  onChange={(value: any) => {
                    setDataRealizadaFim(dayjs(value));
                  }}
                  ampmInClock={false}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControlStato sx={{ width: '100%', marginTop: '25px' }} variant="filled">
          <NumericFormat
            onChange={(e) => console.debug(e.target.value)}
            onValueChange={(e) => setValorHora(e.floatValue ?? 0)}
            prefix="R$"
            thousandSeparator="."
            decimalSeparator=","
            label="Valor Hora"
            customInput={TextFieldStato}
            valueIsNumericString
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
          />
        </FormControlStato>

        <Grid item>{loadingSave && <LinearProgress />}</Grid>

        <GridItemCadastroBtnSalvar item xs={11} md={11}>
          <ButtonStato
            variant="contained"
            onClick={() => {
              inserirRuniao();
            }}
            disabled={
              !descricao ||
              !dataRealizada ||
              !dataRealizadaInicio ||
              !dataRealizadaFim ||
              !valorHora ||
              loadingSave
            }
          >
            Inserir
          </ButtonStato>
        </GridItemCadastroBtnSalvar>
      </Grid>
    </Drawer>
  );
};

export default DrawerReuniaoInterna;
