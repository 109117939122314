import {
  FormControl,
  Grid,
  Drawer,
  Typography,
  LinearProgress,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { NumericFormat } from 'react-number-format';
import {
  IComandaEtapaReunioes,
  IEtapa,
} from '../../../../../commons/types/crm';
import { IAuth, ModeloComercial, TipoConsultor } from '../../../../../commons/types/usuatios';
import { ButtonStato } from '../../../../../commons/styleds/buttons';
import { IconDrawer } from '../../../../../commons/styleds/icons';
import { SelectStato } from '../../../../../commons/styleds/selects';
import { TextFieldStato } from '../../../../../commons/styleds/textFields';
import { GridItemCadastroBtnSalvar } from '../../../../../commons/styleds/grids';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../../../states/usuarios/AuthState';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IDrawerReunioes {
  open: boolean;
  setOpen: Function;
  editando: boolean;
  setEditando: Function;
  reuniao: IComandaEtapaReunioes;
  setReuniao: Function;
  etapasSelect: IEtapa[];
  idComanda: number;
  loadingSave: boolean;
  consultores: IAuth[];
  adicionarNaLista: any;
  revisarReuniao: Function;
  buComanda?: TipoConsultor;
  modeloComercial?: ModeloComercial;
}

const DrawerReunioes: React.FC<IDrawerReunioes> = ({
  open,
  setOpen,
  editando,
  setEditando,
  reuniao,
  setReuniao,
  etapasSelect,
  idComanda,
  loadingSave,
  consultores,
  adicionarNaLista,
  revisarReuniao,
  buComanda,
  modeloComercial,
}) => {
  const authState = useRecoilValue(authAtom);

  const closeDrawer = () => {
    setEditando(false);
    setOpen(false);
  };

  const handleChangeConsultor = (event: any) => {
    const consultorOnboarding = consultores.find((f) => f.id === event.target.value);
    if (!consultorOnboarding) return;

    let valor: number | undefined = 0;
    const tipo = consultorOnboarding.consultorTipos?.find((t) => t.tipo == buComanda);
    if (tipo)
      valor = modeloComercial == ModeloComercial.B2B ? tipo.valorHoraB2B : tipo.valorHoraB2C;

    setReuniao({
      ...reuniao,
      idConsultorResponsavel: event.target.value,
      valorHora: valor,
    });
  };

  return (
    <Drawer
      style={{ minWidth: '250px' }}
      anchor={'right'}
      open={open}
      onClose={() => closeDrawer()}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => closeDrawer()}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant="h5" sx={{ paddingBottom: '20px' }}>
              {editando ? 'Ações reunião' : 'Agendar reunião'}
            </Typography>
          </Grid>
        </Grid>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Etapas</InputLabel>
          <SelectStato
            required
            label="Etapas"
            disabled={editando}
            value={reuniao.tbEtapaId}
            onChange={(event: any) => {
              setReuniao({
                ...reuniao,
                tbEtapaId: event.target.value,
                comandaId: idComanda!,
              });
            }}
          >
            {etapasSelect?.map((etapa: IEtapa, index: number) => {
              return (
                <MenuItem value={etapa.id} key={index}>
                  {etapa.nome}
                </MenuItem>
              );
            })}
          </SelectStato>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: '20px' }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              clearButtonLabel: 'Empty',
              todayButtonLabel: 'Now',
            }}
          >
            <DesktopDatePicker
              value={reuniao.dtPrevista ? dayjs(reuniao.dtPrevista).startOf('day') : null}
              label="Data Prevista"
              format="DD/MM/YYYY"
              defaultValue={dayjs(reuniao.dtPrevista).startOf('day')}
              onChange={(value: any) => { 
                const updatedDate = dayjs(value).startOf('day').toDate();
                setReuniao({ ...reuniao, dtPrevista: updatedDate });
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
            >
              <DemoItem label={'Hora Início'}>
                <TimePicker
                  sx={{ width: '100% !important' }}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  label="Hora Início"
                  ampm={false}
                  ampmInClock={false}
                  value={reuniao.dtRealizadaInicio ? dayjs(reuniao.dtRealizadaInicio) : null}
                  onChange={(newValue) => {
                    const localDate = newValue
                      ? dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss')
                      : null;
                    setReuniao({
                      ...reuniao,
                      dtRealizadaInicio: localDate,
                    });
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
            >
              <DemoItem label={'Hora Fim'}>
                <TimePicker
                  sx={{ width: '100% !important' }}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  label="Hora Fim"
                  ampm={false}
                  ampmInClock={false}
                  value={reuniao.dtRealizadaFim ? dayjs(reuniao.dtRealizadaFim) : null}
                  onChange={(newValue) => {
                    const localDate = newValue
                      ? dayjs(newValue)
                      : null;
                    setReuniao({
                      ...reuniao,
                      dtRealizadaFim: localDate ? localDate.format() : null,
                    });
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      variant: 'outlined',
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>

        <FormControl
          required
          fullWidth
          variant="outlined"
          sx={{ marginTop: '20px' }}
        >
          <InputLabel id="demo-simple-select-label">Consultores</InputLabel>
          <SelectStato
            required
            label="Consultores"
            value={reuniao.idConsultorResponsavel}
            onChange={(event: any) => {
              handleChangeConsultor(event);
            }}
          >
            {consultores?.map((consultor: IAuth, index: number) => {
              return (
                <MenuItem value={consultor.id} key={index}>
                  {consultor.nome}
                </MenuItem>
              );
            })}
          </SelectStato>
        </FormControl>

        {loadingSave && <LinearProgress />}

        <GridItemCadastroBtnSalvar item xs={11} md={11}>
          <ButtonStato variant="contained" onClick={adicionarNaLista}>
            Agendar
          </ButtonStato>
          {editando && (
            <>
              <Tooltip title={reuniao.idConsultorResponsavel !== authState?.id ? "Você não é o consultor responsável" : ""}>
                <span>
                  <ButtonStato
                    variant="contained"
                    onClick={() => revisarReuniao('concluir')}
                    disabled={reuniao.idConsultorResponsavel !== authState?.id}
                    color="success"
                  >
                    Concluir
                  </ButtonStato>
                </span>
              </Tooltip>
              
              <Tooltip title={reuniao.idConsultorResponsavel !== authState?.id ? "Você não é o consultor responsável" : ""}>
                <span>
                  <ButtonStato
                    variant="contained"
                    disabled={reuniao.idConsultorResponsavel !== authState?.id}
                    onClick={() => revisarReuniao('noShow')}
                    color="error"
                  >
                    No Show
                  </ButtonStato>
                </span>
              </Tooltip>
            </>
          )}
        </GridItemCadastroBtnSalvar>
      </Grid>
    </Drawer>
  );
};

export default DrawerReunioes;
