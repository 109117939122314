import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PPCStepper1 from './Steps/stepper1';
import PPCStepper2 from './Steps/stepper2';
import PPCStepper3 from './Steps/stepper3';
import PPCStepper4 from './Steps/stepper4';
import PPCStepper5 from './Steps/stepper5';
import { FormProvider, useForm } from 'react-hook-form';
import PPCStepper6 from './Steps/stepper6';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { usePerfilActions } from '../../../../states/perfis/perfil.actions';
import { IAnalisePerfil, IComanda } from '../../../../commons/types/crm';
import { useComandaActions } from '../../../../states/comandas/comanda.actions';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ width: '50% !important' }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const empty: IAnalisePerfil = {
    id: 0,
    contatoId: 0,
    informacaoGeralP1: '',
    informacaoGeralP2: '',
    informacaoGeralP3: '',
    informacaoGeralP4: '',
    informacaoGeralP5: '',
    informacaoGeralP6: '',
    informacaoGeralP7: '',
    informacaoGeralP8: '',
    informacaoGeralP9: '',
    informacaoGeralP10: '',
    informacaoGeralP11: '',
    informacaoGeralP12: '',
};

interface IParams {
    id?: string;
}

const PPC: React.FC = () => {
    const { id } = useParams<IParams>();
    const [value, setValue] = React.useState(0);

    const methods = useForm({ defaultValues: empty });

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const analisePerfisActions = usePerfilActions();
    const comandaActions = useComandaActions();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const onSubmit = (data: IAnalisePerfil) => {

    };

    const handleChangeNext = () => {
        if (value < 5)
            setValue(value + 1);
    };

    const handleChangeBack = () => {
        if (value > 0)
            setValue(value - 1);
    };

    const getPPC = () => {

        comandaActions
            .get(Number(id))
            .then((data: IComanda) => {

                analisePerfisActions.list(false, `?contatoId=${data.contato?.id}`).then((resp: IAnalisePerfil[]) => {
                    if (resp.length > 0) {
                        methods.reset(resp[0], { keepDefaultValues: true });
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1500);

                }).catch((err: any) => toast.warn(err));

            })
            .catch((error: any) => toast.error(error))
            .finally(() => setLoading(false));

    };

    useEffect(() => {

        getPPC();

    }, []);

    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", backgroundColor: "white" }}>
            <Grid container direction="row" spacing={0} sx={{ display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>

                <Grid xs={12} md={12}>
                    <Typography sx={{ padding: "10px" }} variant="h4" component="h2">
                        Análise de Perfil e Planejamento de Carreira
                    </Typography>
                    <Divider orientation="horizontal" />
                </Grid>

                <FormProvider {...methods}>
                    <FormStato
                        id='formArea'
                        onSubmit={methods.handleSubmit(onSubmit)}
                        style={{ width: '100%' }}
                    >
                        <Grid container xs={12} md={12} sm={12} lg={12}>
                            <Box sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="# 1" {...a11yProps(0)} />
                                    <Tab label="# 2" {...a11yProps(1)} />
                                    <Tab label="# 3" {...a11yProps(2)} />
                                    <Tab label="# 4" {...a11yProps(3)} />
                                    <Tab label="# 5" {...a11yProps(4)} />
                                    <Tab label="# 6" {...a11yProps(5)} />
                                    {/* <Tab label="# 7" {...a11yProps(6)} /> */}
                                </Tabs>

                                <Box sx={{ width: '100%', paddingTop: "5px" }}>
                                    {loadingSave &&
                                        <LinearProgress />
                                    }
                                </Box>
                            </Box>
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                            <CustomTabPanel value={value} index={0}>
                                <PPCStepper1 novoUsuario={false}></PPCStepper1>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <PPCStepper2 novoUsuario={false}></PPCStepper2>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <PPCStepper3 novoUsuario={false}></PPCStepper3>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <PPCStepper4 novoUsuario={false}></PPCStepper4>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <PPCStepper5 novoUsuario={false}></PPCStepper5>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={5}>
                                <PPCStepper6 novoUsuario={false}></PPCStepper6>
                            </CustomTabPanel>
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{
                            margin: "0px 20px",
                        }}>
                            <Grid container xs={6} md={6} sm={6}>
                                <ButtonHome
                                    // type="submit"
                                    variant="contained"
                                    onClick={handleChangeBack}
                                    disabled={value === 0}
                                >
                                    Anterior
                                </ButtonHome>
                            </Grid>
                            <Grid container xs={6} md={6} sm={6}
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <ButtonHome
                                    // type="submit"
                                    variant="contained"
                                    onClick={handleChangeNext}
                                    disabled={value === 5}
                                >
                                    Próximo
                                </ButtonHome>
                            </Grid>
                        </Grid>

                    </FormStato>
                </FormProvider>

            </Grid>
        </GridContainer >
    );
};

const FormStato = styled.form`
`;

const ButtonHome = styled(Button)`
  color: white !important;
  border-radius: 10px !important;
`;

export const GridContainer = styled(Grid)`
  margin: 0px !important;
  height: 100% !important;
  width: 100%;
`;


export default PPC;

