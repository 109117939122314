import { atom } from "recoil";
import { IReuniao, IReuniaoInterna } from "../../commons/types/crm";

export const reunioesInternasAtom = atom({
  key: "reunioesInternas",
  default: localStorage.getItem("reunioesInternas")
    ? (JSON.parse(
        localStorage.getItem("reunioesInternas")!,
      ) as IReuniaoInterna[])
    : null,
});
