import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { useComandaActions } from '../../states/comandas/comanda.actions';
import { useEffect, useState } from 'react';
import { IComanda } from '../../commons/types/crm';
import { toast } from 'react-toastify';
import { PaperReunioes } from '../Reunioes';
import { DataGrid } from '@mui/x-data-grid';
import { columnsAssessorados } from './columns';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comandaAtom } from '../../states/comandas/ComandaState';
import { ptBR } from '@mui/material/locale';
import { useLocation } from 'react-router-dom';
import { authAtom } from '../../states/usuarios/AuthState';

const Assessorados = () => {
  const auth = useRecoilValue(authAtom);
  const comandaActions = useComandaActions();
  const [comandaState, setComandaState] = useRecoilState(comandaAtom);
  const [comandas, setComandas] = useState<IComanda[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const search = useLocation().search;

  const buscaInicial = async () => {
    try {
      const searchParams = new URLSearchParams(search);
      const textoBusca = searchParams.get('busca');
      const query = textoBusca ? `?TextoBusca=${textoBusca}` : '';

      const data = await comandaActions.list(false, query);
      const resultadoAtivo = data.resultado.filter((comanda: IComanda) => comanda?.contato?.ativo && comanda?.idConsultorMaster == auth?.id);

      setComandaState(resultadoAtivo as IComanda[]);
      setComandas(resultadoAtivo as IComanda[]);
    } catch (error) {
      toast.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    buscaInicial();
  }, [search]);

  return (
    <PaperReunioes elevation={3}>
      <Typography variant='h4'>Comandas Ativas</Typography>
      <br />
      <ThemeProvider theme={createTheme({}, ptBR)}>
        <DataGrid
          rows={comandas}
          columns={columnsAssessorados}
          loading={loading}
          sx={{
            borderRadius: '10px',
            minHeight: '700px',
            height: 'auto',
          }}
        />
      </ThemeProvider>
    </PaperReunioes>
  );
};

export default Assessorados;
