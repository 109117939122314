import { historicoAcoesAtom } from './HistoricoAcoesState';
import { useBaseRootActions } from '../baseRoot.actions';

function useHistoricoAcoesActions() {
  const baseActions = useBaseRootActions('acoes', historicoAcoesAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData,
    updateFormData: baseActions.updateFormData
  };
}

export { useHistoricoAcoesActions };
