import { useRecoilValue } from 'recoil';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { useBaseActions } from '../base.actions';
import { authAtom } from '../usuarios/AuthState';
import { reunioesAtom } from './ReuniaoState';

function useReuniaoActions() {
  const authState = useRecoilValue(authAtom);
  const recurso = 'reunioes';
  const baseActions = useBaseActions(recurso, reunioesAtom, false);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/consultores/${authState?.id}/${recurso}`;
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    update: baseActions.update,
    create: baseActions.create,
    patch: baseActions.patch,
    delete: baseActions.delete,
    listTimeSheet,
  };

  async function listTimeSheet(query: string = '') {
    let url = baseUrl + '/timesheet';
    if (query !== '') url += query;

    const recursos = await axiosWrapper.get(url);
    return recursos;
  }
}

export { useReuniaoActions };
