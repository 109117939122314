import { FormControl, Grid, InputLabel, MenuItem, Paper } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ButtonStato } from '../../../commons/styleds/buttons';
import { SelectStato } from '../../../commons/styleds/selects';
import {
  IComandaEtapaReunioes,
  IReuniaoInterna,
  StatusReuniaoEnum,
} from '../../../commons/types/crm';
import { useStatusActions } from '../../../states/comandas/status/Status.actions';
import { useReuniaoActions } from '../../../states/reunioes/reuniao.actions';
import { useReuniaoInternaActions } from '../../../states/reunioesInternas/reunioesInternas.actions';
import TimesheetRestultado from './Components/resultado';

enum periodoEnum {
  MES_ATUAL = 'Mês Atual',
  MES_PASSADO = 'Mês Passado',
  MES_RETRASADO = 'Mês Retrasado',
  RANGE_DATAS = 'Range de Datas',
}

const RelatorioTimeSheet: React.FC = () => {
  const statusActions = useStatusActions();
  const reunioesActions = useReuniaoActions();
  const reunioesInternasActions = useReuniaoInternaActions();
  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const [periodo, setPeriodo] = useState<string>('RANGE_DATAS');
  const [comandaEtapasReunioes, setComandaEtapasReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [reunioesInternas, setReunioesInternas] = useState<IReuniaoInterna[]>([]);

  const onChangePeriodo = (valor: string) => {
    setPeriodo(valor);

    if (valor === 'RANGE_DATAS') {
      setDataInicio(undefined);
      setDataFim(undefined);
      return;
    }

    const now = new Date();
    switch (valor) {
      case 'MES_ATUAL':
        setDataInicio(new Date(now.getFullYear(), now.getMonth(), 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth() + 1, 0));
        break;
      case 'MES_PASSADO':
        setDataInicio(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth(), 0));
        break;
      case 'MES_RETRASADO':
        setDataInicio(new Date(now.getFullYear(), now.getMonth() - 2, 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth() - 1, 0));
        break;
      default:
        break;
    }
  };

  const validaPodeEnviar = () => {
    if (periodo === 'RANGE_DATAS' && (!dataInicio || !dataFim)) {
      toast.warn('É necessário preencher as duas datas');
      return false;
    }

    return true;
  };

  const getDataBusca = () => {
    const data = {
      dataInicio: dataInicio?.toISOString(),
      dataFim: dataFim?.toISOString(),
    };

    return data;
  };

  const filtrarRelatorio = (e: any) => {
    e.preventDefault();
    if (!validaPodeEnviar()) return;

    setLoading(true);

    const data = getDataBusca() as any;
    data.statusReuniao = StatusReuniaoEnum.APROVADA;
    const filteredData = Object.entries(data)
      .filter(([_, value]) => value !== null && value !== undefined)
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value!.toString();
          return acc;
        },
        {} as Record<string, string>,
      );
    const queryString = new URLSearchParams(filteredData).toString();

    reunioesActions
      .listTimeSheet(`?${queryString}`)
      .then((resp: any) => setComandaEtapasReunioes(resp.resultado))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));

    reunioesInternasActions
      .list(false, `?${queryString}`)
      .then((resp: any) => setReunioesInternas(resp))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <DivConteudo>
        <Grid item xs={12}>
          <h2>Preencha as informações para extrair o relatório:</h2>
        </Grid>

        <form>
          <PaperRelatorio elevation={3}>
            <GridTimeSheet container sx={{ width: '85%' }} spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Período</InputLabel>
                  <SelectStato
                    value={periodo}
                    onChange={(e) => onChangePeriodo(e.target.value as string)}
                    required
                    label="Período"
                  >
                    {Object.keys(periodoEnum).map((periodo: string, index: number) => (
                      <MenuItem value={periodo} key={index}>
                        {periodoEnum[periodo as keyof typeof periodoEnum]}
                      </MenuItem>
                    ))}
                  </SelectStato>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                {periodo === 'RANGE_DATAS' && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      value={dayjs(dataInicio)}
                      onChange={(value) => setDataInicio(value!.toDate() as Date)}
                      label="Data Início"
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                {periodo === 'RANGE_DATAS' && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      value={dayjs(dataFim)}
                      onChange={(value) => setDataFim(value!.toDate() as Date)}
                      label="Data Fim"
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Grid>

              <Grid item sx={{ display: 'flex', width: '100%' }}>
                <Grid sx={{ marginRight: '15px' }}>
                  <ButtonStato
                    type="submit"
                    variant="outlined"
                    onClick={filtrarRelatorio}
                    disabled={loading}
                  >
                    Filtrar
                  </ButtonStato>
                </Grid>
              </Grid>
            </GridTimeSheet>
          </PaperRelatorio>
        </form>

        {(comandaEtapasReunioes.length > 0 || reunioesInternas.length > 0) && (
          <TimesheetRestultado
            dataInicio={dataInicio!}
            dataFim={dataFim!}
            resultado={comandaEtapasReunioes}
            reunioesInternas={reunioesInternas}
          />
        )}
      </DivConteudo>
    </>
  );
};

const GridTimeSheet = styled(Grid)`
  padding: 20px 0 0 40px;
  min-height: 200px;
  display: flex;
  align-items: center;
`;

const DivConteudo = styled.div`
  max-width: 85vw;
  margin: 0 auto;
  padding-left: 50px;
`;

export const PaperRelatorio = styled(Paper)`
  padding: 20px;
  border-radius: 40px !important;
  width: 100%;
  min-width: 65vw;
  min-height: 200px;
`;

export default RelatorioTimeSheet;
