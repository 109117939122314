import axios, { AxiosError, AxiosResponse } from 'axios';
import { useRecoilState } from 'recoil';
import { history } from '../helpers/history';
import { toast } from 'react-toastify';
import { authAtom } from '../states/usuarios/AuthState';

export function useAxiosWrapper() {
  const [auth, setAuth] = useRecoilState(authAtom);

  return {
    get: request('GET'),
    put: request('PUT'),
    post: request('POST'),
    patch: request('PATCH'),
    delete: request('DELETE'),
    postFormData: request('POST', 'multipart/form-data'),
    putFormData: request('PUT', 'multipart/form-data')
  };

  function request(metodo: string, contentType?: string) {
    return (url: string, body?: object | undefined) => {
      const options = {
        url,
        method: metodo,
        headers: authHeader(url),
        data: body,
      };

      if (body) options.headers['Content-Type'] = 'application/json';

      if(contentType) options.headers['Content-Type'] = contentType;

      return axios(options)
        .then(handleResponse)
        .catch(handleErrorResponse);
    };
  }

  function authHeader(url: string) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = auth?.token;
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL!);

    let headers: { [k: string]: any } = {};

    if (isLoggedIn && isApiUrl) headers.Authorization = `Bearer ${token}`;

    return headers;
  }

  function handleErrorResponse(response: AxiosError<any, any>) {
    if ([401,].includes(response.response?.status ?? 0) && auth?.token) {
      localStorage.removeItem('usuario');
      setAuth(null);
      history.push('/login');
    }

    const data = response.response?.data;
    const error = (data && data?.mensagem) || response.response?.statusText;

    if ((data?.details as any[])?.length > 0) 
      data?.details.map((d: string) => toast.error(d))
    else
      toast.error(error);

    return Promise.reject(error);
  }

  function handleResponse(response: AxiosResponse<any, any>) {
    return response.data;
  }
}
