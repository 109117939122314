import { IIdNome } from './base';

export interface IAuth {
  id?: number;
  nome?: string;
  email?: string;
  celular?: string;
  token?: string;
  foto?: string;
  tipoConsultorB2B: boolean;
  tipoConsultorB2C: boolean;
  valorHoraB2b: number;
  valorHoraB2C: number;
  consultorTipos?: IConsultorTipo[];
}

export interface IModulos {
  role?: string;
  modulo?: string;
}

export interface IPermissaoModulo {
  id?: number;
  tbUsuarioId?: number;
  tbModuloId: number;
  role: string;
}

export enum TipoConsultor {
  Prime = 'Prime',
  Exec = 'Exec',
  Profissional = 'Profissional',
}

export interface IConsultorTipo {
  id: number;
  tipo: TipoConsultor;
  consultorId: number;
  valorHoraB2C?: number;
  valorHoraB2B?: number;
}

export interface IUsuario extends IIdNome {
  email: string;
  senha?: string;
  telefone?: string;
  celular?: string;
  cargo?: string;
  valorHoraB2C?: number;
  valorHoraB2B?: number;
  tipoConsultorB2C?: boolean;
  tipoConsultorB2B?: boolean;
  foto?: string;
  consultorTipos?: IConsultorTipo[];
}

export enum ModeloComercial {
  B2B = 'B2B',
  B2C = 'B2C',
}
