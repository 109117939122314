import { useBaseActions } from "../base.actions";
import { reunioesInternasAtom } from "./ReuniaoInternaState";

function useReuniaoInternaActions() {
  const baseActions = useBaseActions(
    "reunioesInternas",
    reunioesInternasAtom,
    false,
  );

  return {
    create: baseActions.create,
    update: baseActions.update,
    get: baseActions.get,
    list: baseActions.list,
  };
}

export { useReuniaoInternaActions };
